import { useContext, useState } from 'react';

import { navigate } from 'gatsby';
import jwt_decode from 'jwt-decode';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

export const useAuth = () => {
    const context = useContext(GlobalContext);
    const [authLoading, setAuthLoading] = useState(false);

    const adminCheck = () => {
        const user = store.get('user');
        if (!user?.roles?.includes('admin')) {
            navigate('/');
        }
    };

    const isAdmin = store.get('user')?.roles?.includes('admin');

    const localLoginCheck = () => {
        const localToken = store.get('token');
        const user = store.get('user');
        const isLoginPage = window.location.pathname.includes('login');
        if (!localToken || !user) {
            !localToken && console.log('Token not found.');
            !user && console.log('Current user details not found.');
            store.remove('token');
            store.remove('user');
            !isLoginPage && navigate('/login');
        } else {
            const decodedToken: { exp: number } = jwt_decode(localToken);
            const currentDate = new Date();
            context.setLoggedInUser(user);

            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log('Token expired.');
                store.remove('token');
                store.remove('user');
                !isLoginPage && navigate('/login');
            } else {
                return true;
            }
        }
    };

    const login = async (emailId: string, password: string) => {
        try {
            setAuthLoading(true);
            const loginResponse = await apiRequester.login({ emailId, password });
            const { token, user } = loginResponse;
            store.set('token', token);
            store.set('user', user);
            const { roles } = user as Users.User;
            context.setLoggedInUser(user);
            if (roles?.includes('admin')) navigate('/admin/users');
            else navigate('/');
        } catch (err) {
            handleError(err);
        } finally {
            setAuthLoading(false);
        }
    };

    const forgotPassword = async (emailId: string) => {
        try {
            setAuthLoading(true);
            await apiRequester.forgotPassword({ emailId });
            handleSuccess('Password reset link sent to your email.');
        } catch (err) {
            handleError(err);
        } finally {
            setAuthLoading(false);
        }
    };

    const resetPassword = async (newPassword: string, token: string | null) => {
        try {
            setAuthLoading(true);
            await apiRequester.resetPassword({ newPassword, token });
            handleSuccess('Password reset successfully. Sign In to continue');
            store.remove('token');
            store.remove('user');
            navigate('/login');
            return true;
        } catch (err) {
            handleError(err);
            return false;
        } finally {
            setAuthLoading(false);
        }
    };

    const logout = () => {
        store.remove('token');
        store.remove('user');
        navigate('/login');
        context.setLoggedInUser(undefined);
    };

    return {
        authLoading,
        login,
        forgotPassword,
        resetPassword,
        localLoginCheck,
        logout,
        loggedInUser: context.loggedInUser,
        adminCheck,
        isAdmin,
    };
};

export default useAuth;
