export const beautifyName = (name: string) => {
    let modifiedName = name.replace('-', ' ');
    modifiedName = modifiedName.replace(/\w\S*/g, function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return modifiedName;
};

export const getResourceId = (resource: string | ({ _id: string } & any)): string | undefined => {
    return typeof resource === 'string' ? resource : resource?._id;
};

export const getRepeatingSubstrings = (s: string, repeatedSubstrings: string[]): string[] => {
    const words = s.split(' ');
    const substrings = new Set();
    let longestRepeatedSubstring = '';
    for (let i = 0; i < words.length; i++) {
        for (let j = i; j < words.length; j++) {
            const substring = words.slice(i, j + 1).join(' ');
            if (substrings.has(substring.replaceAll('.', ''))) {
                if (substring.length > longestRepeatedSubstring.length) {
                    longestRepeatedSubstring = substring;
                }
            } else {
                substrings.add(substring);
            }
        }
    }

    const validLongestSubstring = longestRepeatedSubstring.trim();
    if (validLongestSubstring.split(' ').length >= 5) {
        repeatedSubstrings.push(longestRepeatedSubstring);
        const updatedtext = s.replaceAll(longestRepeatedSubstring, '');
        getRepeatingSubstrings(updatedtext, repeatedSubstrings);
    }
    return repeatedSubstrings;
};

export const random_bg_color = (): string => {
    const color = 'hsl(' + Math.random() * 360 + ', 100%, 75%)';
    return color;
};
